import { useState } from "react";
import { signup, login } from "../store/session";
import { togglePasswordVisibility } from "./Utils";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SignupHeader from "./SignupHeader";
import PasswordRequirements from "./PasswordRequirements";
import WelcomePane from "./WelcomePane";
import { useHistory } from "react-router-dom";
import {checkErrors} from "./Utils";
import { validInputs } from "./Utils";
import Spinner from "./Spinner";
import { getLoading, setLoading } from "../store/loading";
import { BODY_BOLD, BUTTON_CLASS, BUTTON_CLASS_WHITE, HEADER_2, INPUT_CLASS, LINK_TEXT, TEXT_SMALL_500 } from "../constants";


const Signup = () => {
  
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const loading = useSelector(getLoading);
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoading(1));
    setErrors([]);
    setSubmitted(true);
    let currentErrors = validInputs(password, confirmPassword, name);
    setErrors(currentErrors);

    if (currentErrors.length) {
      dispatch(setLoading(0));
      return null;
    } else {
      currentErrors = [];
      await dispatch(signup({email, password, name}))
      .catch(async (res) => {
        currentErrors = await checkErrors(res, setErrors)
      });      
      dispatch(setLoading(0));
      if (!currentErrors.length) {
        history.push(`/email-confirmation/${email}`);
      }
    }
  };

  const demoLogin = async (e) => {
    e.preventDefault();
    let currentErrors = [];
    dispatch(setLoading(2));
    setErrors([]);
    await dispatch(login({email: 'demo@user.io', password: 'password'}))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setErrors);
    });
    dispatch(setLoading(0));
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setSubmitted(false);
    setPassword(e.target.value);
  };

  const noBlankInputs = () => {
    return email.length && password.length && name.length && confirmPassword.length;
  }

  const passwordInput = document.getElementById("password");
  const confirmPasswordInput = document.getElementById("confirm-password");


  return (
    <div className="flex flex-col w-full h-screen">
      <SignupHeader />
      <div className="flex flex-row h-full">
        { window.innerWidth > 1024 && <WelcomePane /> }
        <div className="flex flex-col items-center justify-center w-full lg:w-1/2">
          <div className="flex flex-col items-center justify-center w-full lg:w-1/2 p-6 lg:p-0">
            <div className="flex flex-row items-center lg:items-start lg:w-full mb-3">
              <h1 className={HEADER_2}>Sign up</h1>
            </div>
            <form onSubmit={handleSubmit}>
              <label className={BODY_BOLD}>Name</label>
              <input className={INPUT_CLASS + 'w-full mb-3'} type="lastName" value={name} onChange={e => setName(e.target.value)} />
              <label className={BODY_BOLD}>Email</label>
              <input className={INPUT_CLASS + 'w-full mb-3'} type="email" placeholder="email@email.com" value={email} onChange={e => setEmail(e.target.value.toLowerCase())} />
              <label className={BODY_BOLD}>Password</label>
              <div className='flex flex-row items-center justify-end w-full mb-2'>
                <input id="password" className={INPUT_CLASS + 'w-full'} type={passwordType} value={password} onChange={handlePasswordChange} />
                {passwordType === 'password' ?
                <i onClick={togglePasswordVisibility(passwordType, setPasswordType, passwordInput)} className="fa-solid fa-eye-slash cursor-pointer absolute mr-3"></i>
                :
                <i onClick={togglePasswordVisibility(passwordType, setPasswordType, passwordInput)} className="fa-solid fa-eye cursor-pointer absolute mr-3"></i>
                }
              </div>
              <PasswordRequirements password={password} submitted={submitted}/>
              <label className={BODY_BOLD}>Re-enter Password</label>
              <div className='flex flex-row items-center justify-end w-full mb-1'>
                <input id="confirm-password" className={INPUT_CLASS + 'w-full '} type={confirmPasswordType} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                {confirmPasswordType === 'password' ?
                <i onClick={togglePasswordVisibility(confirmPasswordType, setConfirmPasswordType, confirmPasswordInput)} className="fa-solid fa-eye-slash cursor-pointer absolute mr-3"></i>
                :
                <i onClick={togglePasswordVisibility(confirmPasswordType, setConfirmPasswordType, confirmPasswordInput)} className="fa-solid fa-eye cursor-pointer absolute mr-3"></i>
                }
              </div>
              {<ul className='mb-3 min-h-[30px]'>
                {errors.map(error => {
                  if (error !== '') {
                    return <li className="text-error-red font-bold" key={error}>{error}</li>
                  }
                })}
              </ul>}
              <button disabled={!noBlankInputs()} className={BUTTON_CLASS + ' w-full mb-4'}>{loading === 1 ? <Spinner/> : 'Sign Up'}
              </button>
              { process.env.NODE_ENV !== 'production' &&
              <button className={BUTTON_CLASS + ' w-full'} onClick={demoLogin}>{loading === 2 ? <Spinner/> : 'Demo Login'}</button>                
              }
            </form>
            <p className={TEXT_SMALL_500 + 'mt-4'}>
              Already have an account? <Link className={LINK_TEXT} to={`/login`}>Log in</Link> 
            </p>
          </div>
        </div>
      </div>
    </div>

  )
}


export default Signup;