import { useParams } from "react-router-dom";
import DashboardHeader from "./DashboardHeader";
import KanbanBoard from "./KanbanBoard";
import { useState } from "react";



const Dashboard = () => {

  const [filterText, setFilterText] = useState('');
  const {contactId} = useParams();

  return (

    <div className="flex flex-col h-[calc(100vh-100px)]">
      <DashboardHeader filterText={filterText} setFilterText={setFilterText}/>
      <KanbanBoard filterText={filterText} contactId={contactId}/>
    </div>

  )

}

export default Dashboard;