import { useState } from "react";
import { isEmail } from "./Utils";
import SignupHeader from "./SignupHeader";
import { useHistory } from "react-router-dom";
import csrfFetch from "../store/csrf";
import toast from "react-hot-toast";
import { forgotPassCall } from "./apiCalls";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, INPUT_CLASS, TEXT_INPUT_CLASS } from "../constants";

const  ForgotPassword = () => {

  const history = useHistory();
  const [email, setEmail] = useState('');


  const handleForgetPassword = async (e) => {
    try {
      await forgotPassCall(email);
      history.push({
        pathname: '/forgot-password-email-sent',
        state: { email }
      });
    }
    catch (resp) {
      const error = await resp.json();
      toast.error(error.message)
    }
  }

  const handleGoHome = () => {
    history.push('/login');
  }


  return (
    <div className="flex flex-col items-center">
      <SignupHeader />
      <div className="flex flex-col items-center justify-start padding-12 mt-12">
        <p className="text-5xl font-bold mb-6">Forgot your password?</p>
        <p className="text-xl font-semibold">Enter your email address.</p>
        <p className="text-lg mb-4">We'll send an email to reset your password.</p>
        <div className="flex flex-col justify-start w-3/5">
          <label className="font-semibold mb-2">Email address</label>
          <input className={INPUT_CLASS} type="email" placeholder="email@email.com" value={email} onChange={e => setEmail(e.target.value)}/>
          <div className="flex justify-end mt-4 w-full">
            <button onClick={handleGoHome} className={BUTTON_CLASS_WHITE}>Cancel</button>
            <button className={BUTTON_CLASS + "ml-6"} onClick={handleForgetPassword} disabled={!isEmail(email)}>Send email</button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default ForgotPassword;