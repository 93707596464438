import { addContact, fetchContacts, getContacts, getNewestNContacts, getUserContacts, updateContactNotFormData } from "../store/contacts";
import ContactColumn from "./ContactColumn";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getCurrentUser } from "../store/session";
import { Redirect } from "react-router-dom";
import { fetchTiers, getUserTiers } from "../store/tiers";
import { DragDropContext } from "react-beautiful-dnd";
import ColumnAdd from "./ColumnAdd";
import ContactShow from "./ContactShow";
import { Modal } from "../context/Modal";


const KanbanBoard = ({filterText, contactId}) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const tiers = useSelector(getUserTiers(currentUser.id));
  const orderedTiers = tiers.sort((a, b) => a.position - b.position);
  const contacts = useSelector(getUserContacts(currentUser.id));
  const numContacts = contacts.length;
  const newestNcontacts = useSelector(getNewestNContacts(10, currentUser.id));
  const targetContact = contactId ? contacts.find(contact => contact.id === parseInt(contactId)) : null;
  const [filteredContacts, setFilteredContacts] = useState([]);
  const visibleContacts = currentUser.plan === 'free'? newestNcontacts : contacts;
  const [openContactFromExtension, setOpenContactFromExtension] = useState(contactId ? true : false);


  useEffect(() => {
    let visibleContacts = currentUser.plan === 'free'? newestNcontacts : contacts;
    if (visibleContacts.length && filterText.length) {
      let filteredCons = visibleContacts.filter(contact => contact.name.toLowerCase().includes(filterText.toLowerCase()) || contact.company.toLowerCase().includes(filterText.toLowerCase()) || contact.title.toLowerCase().includes(filterText.toLowerCase()));
      setFilteredContacts(filteredCons);
    }
  }, [filterText])

  const move = (sourceArray, destinationArray, droppableSource, droppableDestination, dTierPosition) => {
    const sourceClone = Array.from(sourceArray);
    const destClone = Array.from(destinationArray);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    removed.column_order = dTierPosition;
    removed.columnOrder = dTierPosition;
    destClone.splice(droppableDestination.index, 0, removed);
  
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
  
    return result;
  };

  const reorder = (list, startIndex, endestIdex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endestIdex, 0, removed);
  
    return result;
  };

  const getInverseIndex = (array, index) => {
    return array.length - index - 1;
  }
  

  const onDragEnd = (result) => {

    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    const sourceId = +source.droppableId;
    const destId = +destination.droppableId;
    const sTierPosition = tiers.find(tier => tier.id === sourceId).position;
    const dTierPosition = tiers.find(tier => tier.id === destId).position;
    const sTierContacts = contacts.filter(contact => contact.columnOrder === sTierPosition).sort((a, b) => (b.verticalOrder + b.id / 100) - (a.verticalOrder + a.id / 100));
    const dTierContacts = contacts.filter(contact => contact.columnOrder === dTierPosition).sort((a, b) => (b.verticalOrder + b.id / 100) - (a.verticalOrder + a.id / 100));
    const sTierLength = sTierContacts.length;

    if (sourceId === destId) {
      // placed in original index
      if (source.index === destination.index) {
        console.log('source.index === destination.index');
        return;
      }
      const items = reorder(sTierContacts, source.index, destination.index);
      items.forEach((contact, index) => {
        contact.verticalOrder = getInverseIndex(items, index);
        contact.vertical_order = contact.verticalOrder;
        dispatch(updateContactNotFormData(contact));
      });
    } else {
      const moveResult = move(sTierContacts, dTierContacts, source, destination, dTierPosition);
      let draggedContact = moveResult[destId].find(contact => contact.id === +result.draggableId);
      dispatch(addContact(draggedContact));
      moveResult[destId].forEach((contact, index) => {
        contact.verticalOrder = getInverseIndex(moveResult[destId], index);
        contact.vertical_order = contact.verticalOrder;
        dispatch(updateContactNotFormData(contact));
      });
      moveResult[sourceId].forEach((contact, index) => {
        contact.verticalOrder = getInverseIndex(moveResult[sourceId], index);
        contact.vertical_order = contact.verticalOrder;
        dispatch(updateContactNotFormData(contact));
      });
    }
  }


  useEffect(() => {
    if (!contacts.length) dispatch(fetchContacts());
    if (!tiers.length) dispatch(fetchTiers());
  }, []);

  if (!currentUser) {
    return (
      <Redirect to="/"/>
    )
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="border-solid border-4 border-brand-primary flex flex-row w-full justify-start min-h-full m-3">
        <div className="board flex flex-row w-full justify-start overflow-x-auto mb-3 ml-3">
          {orderedTiers.map( tier => (
            <ContactColumn numContacts={numContacts} key={tier.id} tier={tier} contacts={filterText ? filteredContacts : visibleContacts}/>
          ))}
          <ColumnAdd />
        </div>
      </div>
      {(openContactFromExtension && targetContact) && <Modal children={<ContactShow contact={targetContact} setShowContactShow={setOpenContactFromExtension} order={targetContact.columnOrder}/>} />}
    </DragDropContext>
  )

}

export default KanbanBoard;