import { useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../store/session";
import { createInteraction, updateInteraction } from "../store/interactions";
import { useDispatch } from "react-redux";
import { Modal } from "../context/Modal";
import InteractionDelete from "./InteractionDelete";
import { getLoading, setLoading } from "../store/loading";
import Spinner from "./Spinner";
import ContactMethodChoiceDropdown from "./ContactMethodChoiceDropdown";
import { createNotification, getNotificationForInteraction, updateNotification } from "../store/notifications";
import toast from "react-hot-toast";
import { checkErrors, updateArrayAndVariable, removeIdFromStateArray } from "./Utils";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, INPUT_CLASS } from "../constants";


const emptyInteraction = {
  dateContacted: "",
  contactMethod: "",
  notes: "",
  nextContactDate: ""
}

const InteractionShow = ({key = "new", interaction = emptyInteraction, contact, setShowNewInteraction, startOpen = false, setDataChanged}) => {

  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const [dateContacted, setDateContacted] = useState(interaction.dateContacted ? interaction.dateContacted.toString().slice(0, 10) : '');
  const [contactMethod, setContactMethod] = useState(interaction.contactMethod ? interaction.contactMethod : '');
  const [notes, setNotes] = useState(interaction.notes ? interaction.notes : '');
  const [open, setOpen] = useState(startOpen);
  const [nextContactDate, setNextContactDate] = useState(interaction.nextContactDate ? interaction.nextContactDate.toString().slice(0, 10) : '');
  const [showInteractionDelete, setShowInteractionDelete] = useState(false);
  const [errors, setErrors] = useState([]);
  const notification = useSelector(getNotificationForInteraction(interaction.id));


  const validatePayload = () => {
    return (dateContacted.length > 0) && (contactMethod.length > 0) 
  }

  const makeInteraction = async (interaction, isUpdate) => {
    let currentErrors = [];
    const newInteraction = await dispatch(isUpdate ? updateInteraction(interaction) : createInteraction(interaction))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setErrors);
    });
    return newInteraction;
  }


  const handleSaveInteraction = async e => {
    e.preventDefault();
    removeIdFromStateArray(key, setDataChanged);
    dispatch(setLoading(1));
    const newInteraction = {
      date_contacted: dateContacted,
      contact_method: contactMethod,
      notes,
      next_contact_date: nextContactDate,
      contact_id: contact.id,
      user_id: currentUser.id,
      id: interaction.id
    }
    const createdInteraction = await makeInteraction(newInteraction, interaction.id ? true : false)
    
    if (!errors.length) {
      if (newInteraction.next_contact_date) {
        const newNotification = {
          date: newInteraction.next_contact_date,
          user_id: currentUser.id,
          contact_id: contact.id,
          interaction_id: createdInteraction.id,
          title: `Contact ${contact.name}`,
          viewed: false
        }
        dispatch(interaction.id ? updateNotification({id: notification.id, ...newNotification}) : createNotification(newNotification));
      }
  
      // TODO: add error handling
      dispatch(setLoading(0));
      toast.success('Interaction saved.');
    } else {
      alert([errors[0].message]);
    }
    setShowNewInteraction(false);
  }  

  const today = new Date().toISOString().slice(0, 10);

  return (

    <>
      <div className={open ? "flex flex-col items-start bg-background-secondary p-4 mb-5 w-full h-[450px] transition-[height] duration-1000 overflow-hidden delay-0 rounded " : "flex flex-col items-start bg-background-secondary p-4 mb-5 w-full h-[155px] transition-[height] duration-1000 overflow-hidden delay-0 rounded "}>
        <p className="italic mb-3">*Contact date and contact method required to save interaction to your timeline.</p>
        <div className="flex flex-row mb-3 justify-evenly w-full">
          <div className="flex flex-col w-1/4">
            <label className="" htmlFor="dateContacted">*Contact date
              <input className={INPUT_CLASS + 'w-full'} type="date" max={today} id="dateContacted" value={dateContacted} onChange={(e) => updateArrayAndVariable(e, setDataChanged, setDateContacted, key)} />
            </label>
          </div>
          <div className="flex flex-col w-1/4">
            <label htmlFor="contactMethod">*Contact method
              <ContactMethodChoiceDropdown key={key} contactMethod={contactMethod} setContactMethod={setContactMethod} setDataChanged={setDataChanged} />
            </label>
          </div>
          <div className="flex flex-col w-1/4">
            <label htmlFor="nextContactDate">Next contact date </label>
            <input className={INPUT_CLASS + 'w-full'} type="date" min={today} id="nextContactDate" value={nextContactDate} onChange={(e) => updateArrayAndVariable(e, setDataChanged, setNextContactDate, key)} />
          </div>
        </div>
        <div className="w-full text-center min-h-8">
          {!open && <i onClick={e => setOpen(true)} className="fa-solid fa-caret-down cursor-pointer text-lg"></i>}
        </div>
        <div className="flex flex-col w-full mb-3">
          <label htmlFor="notes">Notes
            <textarea className='drop-shadow bg-white border-none h-40 w-full text-left resize-none relative p-1 focus:outline-none focus:ring-2 focus:ring-brand-primary rounded' type="textarea" id="notes" maxLength="500" value={notes} onChange={(e) => updateArrayAndVariable(e, setDataChanged, setNotes, key)} />
          </label>
          <p className="text-right w-full">{notes.length} / 500</p>
        </div>
        <div className="flex flex-row justify-between w-full items-end">
          <button onClick={e => setShowInteractionDelete(true)} className={"flex flex-row justify-between items-center " + BUTTON_CLASS_WHITE}>
            <i className="fa-solid fa-trash-can text-md mr-2 text-brand-primary"></i>
            Delete interaction
          </button>
          <button onClick={handleSaveInteraction} disabled={!validatePayload()} className={BUTTON_CLASS + "min-w-[150px]"}>
            {loading == 1 ? <Spinner /> : 'Save interaction'}
            </button>
        </div>
        {open && <i onClick={e => setOpen(false)} className="fa-solid fa-caret-up cursor-pointer text-lg w-full text-center"></i>}
      </div>
      {showInteractionDelete && <Modal children={<InteractionDelete key={key} interaction={interaction} setShowNewInteraction={setShowNewInteraction} setShowInteractionDelete={setShowInteractionDelete} setDataChanged={setDataChanged} />} />}
    </>

  )

}

export default InteractionShow;