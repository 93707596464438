import { createTier, getUserTiers } from "../store/tiers";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../store/session";
import { getLoading, setLoading } from "../store/loading";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
import { BUTTON_CLASS, BUTTON_CLASS_ADD_COLUMN } from "../constants";

const ColumnAdd = () => {

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const userTiers = useSelector(getUserTiers(currentUser.id));
  const loading = useSelector(getLoading);

  const getNewColPosition = (tiers) => {
    let max = 0;
    tiers.forEach(tier => {
      if (tier.position > max) max = tier.position;
    });
    return max + 1;
  }

  const handleAddColumn = async () => {
    dispatch(setLoading(1));
    await dispatch(createTier({name: 'New Column', user_id: currentUser.id, position: getNewColPosition(userTiers)}));
    dispatch(setLoading(0));
    toast.success(`New column created.`)
  }

  return (

    <button onClick={handleAddColumn} className={BUTTON_CLASS_ADD_COLUMN + " min-w-[300px] m-3 ml-0 h-10"}>
      {loading == 1 ? <Spinner /> : '+ Add Column'}
    </button>

  )

}

export default ColumnAdd;