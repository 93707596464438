import { useParams, useHistory } from "react-router-dom";
import SignupHeader from "./SignupHeader";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, TEXT_INPUT_CLASS } from "../constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, getUserByEmail, updateUser } from "../store/users";
import toast from "react-hot-toast";
import { getCurrentUser, logout } from "../store/session";
import PasswordRequirements from "./PasswordRequirements";
import { togglePasswordVisibility } from "./Utils";

const ResetPassword = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [email, token] = [useParams().email, useParams().token];
  const [submitted, setSubmitted] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const targetUser = useSelector(getUserByEmail(email));
  const currentUser = useSelector(getCurrentUser);

  if (currentUser) {
    dispatch(logout());
  }

  useEffect(() => {
    dispatch(fetchUsers());
  }
  , [token])

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setSubmitted(false);
    setPassword(e.target.value);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    try {
      const updatedUser = await dispatch(updateUser({id: targetUser.id, reset_password_token: null, password}))
      console.log('updatedUser', updatedUser)
      if (updatedUser && updatedUser.id) {
        console.log('in success')
        history.push('/password-updated');
      } else {
        toast.error('Could not update password.');
      }
    } catch {
      toast.error('Could not update password.');
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    history.push('/login');
  }

  if (!targetUser || !targetUser.id || targetUser.resetPasswordToken !== token) {
    return (
      <div className="flex flex-col items-center">
        <SignupHeader />
        <div className="flex flex-col items-center justify-center mt-20">
          <p className="text-3xl fon-bold">Invalid Link</p>
          <p className="mt-4">The link you followed is invalid or expired. Please try again.</p>
        </div>
      </div>
    )
  }

  const passwordInput = document.getElementById("password");
  const confirmPasswordInput = document.getElementById("confirmPassword");

  return (
    <div className="flex flex-col items-center">
      <SignupHeader />
      <div className="flex flex-col items-center justify-center mt-20">
        <p className="text-3xl fon-bold">Reset Password</p>
        <form className="flex flex-col items-start justify-start p-8 min-w-[400px]">
          <label className="font-semibold" htmlFor="password">Enter new password</label>
          <div className='flex flex-row items-center justify-end w-full mb-5'>
            <input className="drop-shadow bg-white border-none h-8 w-full mb-1" value={password} onChange={handlePasswordChange} type={passwordType} id="password" name="password" />
            {passwordType === 'password' ?
            <i onClick={togglePasswordVisibility(passwordType, setPasswordType, passwordInput)} className="fa-solid fa-eye-slash cursor-pointer absolute mr-3"></i>
            :
            <i onClick={togglePasswordVisibility(passwordType, setPasswordType, passwordInput)} className="fa-solid fa-eye cursor-pointer absolute mr-3"></i>
            }
          </div>
          <PasswordRequirements password={password} submitted={submitted}/>
          <label className="font-semibold" htmlFor="confirmPassword">Re-enter new password</label>
          <div className='flex flex-row items-center justify-end w-full mb-5'>
            <input className="drop-shadow bg-white border-none h-8 w-full mb-1" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} type={confirmPasswordType} id="confirmPassword" name="confirmPassword" />
            {confirmPasswordType === 'password' ?
            <i onClick={togglePasswordVisibility(confirmPasswordType, setConfirmPasswordType, confirmPasswordInput)} className="fa-solid fa-eye-slash cursor-pointer absolute mr-3"></i>
            :
            <i onClick={togglePasswordVisibility(confirmPasswordType, setConfirmPasswordType, confirmPasswordInput)} className="fa-solid fa-eye cursor-pointer absolute mr-3"></i>
            }
          </div>
          <div className="flex flex-row w-full justify-end mt-4">
            <button onClick={handleCancel} className={BUTTON_CLASS_WHITE}>Cancel</button>
            <button onClick={handleUpdatePassword} className={BUTTON_CLASS + 'ml-4'}>Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  )

}


export default ResetPassword;