import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateContact } from '../store/contacts';
import { checkErrors } from './Utils';
import { getLoading, setLoading } from '../store/loading';
import Spinner from './Spinner';
import { BUTTON_CLASS, BUTTON_CLASS_WHITE } from '../constants';


const ImageCrop = ({image, setShowImageCrop, contact}) => {

  const TO_RADIANS = Math.PI / 180
  const dispatch = useDispatch();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const blobUrlRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [errors, setErrors] = useState([]);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, rotate, aspect] = [1, 0, 1];
  const loading = useSelector(getLoading);

  const handleSaveImage = async () => {
    let currentErrors = [];
    dispatch(setLoading(1));
    const finishedPhoto = await createCroppedImage();
    const contactFormData = new FormData();
    contactFormData.append('contact[photo]', finishedPhoto);
    contactFormData.append('contact[id]', contact.id);
    await dispatch(updateContact(contactFormData))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setErrors);
    });

    dispatch(setLoading(0));
    if (currentErrors.length === 0) {
      setShowImageCrop(false);
    } else {
      alert(errors[0]);
    }
  }

  const createCroppedImage = async () => {
    const image = imgRef.current
    if (!image || !completedCrop) {
      throw new Error('Crop image does not exist')
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    )

    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY
    const centerX = image.width / 2
    const centerY = image.width / 2
  
    ctx.save()

    ctx.translate(-cropX, -cropY)

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    )

    ctx.restore();

    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/png',
    })

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)
    
    return blob;
  }

  return (

    <div className='flex flex-col justify-center items-center p-5 w-[800px] h-[800px] bg-white rounded border-2 border-brand-primary'>
      <p className='text-3xl font-semibold mb-5'>Crop image</p>
      <div>
        <ReactCrop 
          crop={crop} 
          aspect={1}
          minWidth={100}
          minHeight={100}
          circularCrop
          onChange={c => setCrop(c)} 
          onComplete={(c) => setCompletedCrop(c)}>
            <img 
            className='max-w-full max-h-full' 
            src={image} 
            ref={imgRef}
            />
        </ReactCrop>
      </div>
      <div className='flex flex-row justify-evenly m-5 w-full'>
        <button onClick={e => setShowImageCrop(false)} className={BUTTON_CLASS_WHITE + 'min-w-[150px]'}>Cancel</button>
        <button className={BUTTON_CLASS + 'min-w-[150px]'} disabled={!crop} onClick={handleSaveImage}>
          {loading ? <Spinner /> : 'Save Image'}
          </button>
      </div>
    </div>
  )
}

export default ImageCrop;