import React, { useState } from 'react';
import * as sessionActions from '../store/session';
import { useDispatch, useSelector } from 'react-redux';
import SignupHeader from "./SignupHeader";
import WelcomePane from "./WelcomePane";
import { Link } from 'react-router-dom';
import { checkErrors } from './Utils';
import { getLoading, setLoading } from '../store/loading';
import Spinner from './Spinner';
import { togglePasswordVisibility } from './Utils';
import warningIcon from '../assets/warning_icon.svg';
import { useHistory } from 'react-router-dom';
import { BODY_BOLD, BUTTON_CLASS, HEADER_2, INPUT_CLASS, LINK_TEXT, SUBTITLE, TEXT_SMALL, TEXT_SMALL_500 } from '../constants';


const Login = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(sessionActions.getCurrentUser);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [passwordType, setPasswordType] = useState('password');
  const loading = useSelector(getLoading);

  const handleLogin = async (e) => {
    let currentErrors = [];
    e.preventDefault();
    dispatch(setLoading(1));
    setErrors([]);
    await dispatch(sessionActions.login({email: email.toLowerCase(), password}))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setErrors);
    });
    dispatch(setLoading(0));
  };

  const demoLogin = async (e) => {
    e.preventDefault();
    let currentErrors = [];
    dispatch(setLoading(2));
    await dispatch(sessionActions.login({email: 'demo@user.io', password: 'password'}))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setErrors);
    });
    dispatch(setLoading(0));
  };

  const handleForgotPassword = (e) => {
    history.push('/forgot-password');
  }

  const passwordInput = document.getElementById("password");

  return (
    <div className="flex flex-col w-full h-screen">
      <SignupHeader />
      <div className="flex flex-row h-full">
        { window.innerWidth > 1024 && <WelcomePane /> }
        <div className="flex flex-col items-center justify-center w-full lg:w-1/2">
          <div className="flex flex-col items-center justify-center w-full lg:w-1/2 p-6 lg:p-0">
            <div className='flex flex-col items-center lg:items-start w-full mb-3'>
              <h1 className={HEADER_2}>Log in</h1>
              <h3 className={SUBTITLE}>Log in to your account</h3>
            </div>
            <form className='w-full' onSubmit={handleLogin}>
              {
                errors.length > 0 &&
                <div className='flex flex-row justify-start items-center min-h-[50px] p-4 drop-shadow rounded border-4 border-solid border-warning-yellow mb-4'>
                  <img src={warningIcon} className="w-6 h-6 m-3" />
                  <p>{errors[0]}</p>
                </div>
              }
              <label className={BODY_BOLD}>Email address</label>
              <input className={INPUT_CLASS + 'w-full mb-3'} type="email" placeholder="email@email.com" value={email} onChange={e => setEmail(e.target.value)} />
              <label className={BODY_BOLD}>Password</label>
            <div className='flex flex-row items-center justify-end w-full mb-2'>
              <input id="password" className={INPUT_CLASS + 'w-full mb-3'} type={passwordType} value={password} onChange={e => setPassword(e.target.value)} />
              {passwordType === 'password' ? 
              <i onClick={togglePasswordVisibility(passwordType, setPasswordType, passwordInput)} className="fa-solid fa-eye-slash cursor-pointer absolute mr-3"></i> 
              : 
              <i onClick={togglePasswordVisibility(passwordType, setPasswordType, passwordInput)} className="fa-solid fa-eye cursor-pointer absolute mr-3"></i>
              }
            </div>
              <p onClick={handleForgotPassword} className={LINK_TEXT + 'mb-6'}>Forgot Your Password?</p>
              <button disabled={!email || !password} className={BUTTON_CLASS + ' w-full mb-4'}>
              {loading == 1 ? <Spinner/> : "Log In"} 
              </button>
              { process.env.NODE_ENV !== 'production' &&
              <button className={BUTTON_CLASS + ' w-full mb-6'} onClick={demoLogin}>
              {loading == 2 ? <Spinner/> : "Demo Login"} 
              </button>
              }
            </form>
            <p className={TEXT_SMALL_500}>
              Don't have an account? <Link className={LINK_TEXT} to={`/signup`}>Sign up</Link> 
            </p>
          </div>
        </div>
      </div>     
    </div>

  )

}


export default Login;