import { useDispatch, useSelector } from "react-redux";
import { deleteContact } from "../store/contacts";
import { getLoading, setLoading } from "../store/loading";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, HEADER_3 } from "../constants";


const ContactDelete = ({contactId, setShowDeleteModal, setShowAddContact, setStopPropagation}) => {

  const dispatch = useDispatch();
  const loading = useSelector(getLoading);

  const handleDeleteContact = async e => {
    e.preventDefault();
    dispatch(setLoading(1));  
    if (contactId) await dispatch(deleteContact(contactId));
    dispatch(setLoading(0));
    setShowDeleteModal(false);
    if (setShowAddContact) setShowAddContact(false);
    if (setStopPropagation) setStopPropagation(false);
    toast.success('Contact deleted.')
  }

  const handleCancel = e => {
    e.preventDefault();
    setShowDeleteModal(false);
    if (setStopPropagation) setStopPropagation(false);
  }

  return (

    <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary bg-white">
      <p className={HEADER_3}>Delete Contact?</p>
      <p className="mb-[32px]">All contact information will be lost.</p>
      <div className="flex flex-row justify-end">
        <button className={BUTTON_CLASS_WHITE + 'min-w-[100px] mr-[32px]'} onClick={handleCancel}>Cancel</button>
        <button className={BUTTON_CLASS + 'min-w-[150px]'} onClick={handleDeleteContact}>
          {loading == 1 ? <Spinner /> : 'Delete Contact'}
          </button>
      </div>
    </div>

  )

}

export default ContactDelete;