import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "../store/session";
import { useState } from "react";
import csrfFetch from "../store/csrf";
import toast from "react-hot-toast";
import { getLoading, setLoading } from '../store/loading';
import Spinner from './Spinner';
import { BUTTON_CLASS, BUTTON_CLASS_WHITE } from "../constants";


const ChangePlan = ({setShowChangePlanModal, plan, setFetchSubFlag}) => {

  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const currentUser = useSelector(getCurrentUser);
  const loading = useSelector(getLoading);

  const handleChooseFree = async () => {
    dispatch(setLoading(1));
    let response;
    if (currentUser.stripeId) {
      response = await csrfFetch("/api/cancel-subscription", {
      method: "POST",
      body: JSON.stringify({userId: currentUser.id}),
      });
      if (response.status == 200) {
        toast.success('You have successfully downgraded your subscription but will retain your benefits until your subscription expires.');
      } else if (response.status == 201) {
        toast.error('There is no active  Stripe subscription associated with this account. Please contact us for assistance.');
      } else {
        toast.error('There was an error downgrading your subscription. Please try again or contact us for assistance.')
      }
    } else {
      toast.error('There was an error downgrading your subscription. Please try again or contact us for assistance.')
    }
    if (!currentUser.stripeId || response.ok) {
    } else {
      toast.error('There was an error downgrading your subscription. Please try again or contact us for assistance.')
    }
    dispatch(setLoading(0));
    setShowChangePlanModal(false);
    if (setFetchSubFlag) setFetchSubFlag(prev => prev + 1);
  }

  const handleChoosePremium = async () => {
    dispatch(setLoading(1));
    let response = await csrfFetch("/api/create-checkout-session", {
      method: "POST",
      body: JSON.stringify({userId: currentUser.id, plan: plan.name}),
    });
    setShowChangePlanModal(false);
    if (response.ok) {
      if (response.status == 201) toast.success('There is an active subscription associated with this account. Please manage your subscription through the Stripe portal.');
      let link = response.headers.get('Location');
      window.open(link, '_blank');
    } else {
      alert('There was an error upgrading your plan. Please try again or contact us for assistance.')
    }
    dispatch(setLoading(0));
  }

  const handleCancel = e => {
    e.preventDefault();
    setShowChangePlanModal(false);
  }

  const planName = plan.name ? plan.name : plan;

  return (

    <>

      {planName.toLowerCase() == 'premium' ?

      <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary">
        <p className="text-3xl pr-20">Cancel premium plan?</p>
        <p>Your premium plan will continue until {currentUser.premiumExpiryDate}</p>
        <p>Thereafter, only the 10 newest contact cards will be visible.</p>
        <div className="flex flex-row justify-end">
          <button className={BUTTON_CLASS_WHITE} onClick={handleCancel}>Cancel</button>
          <button className={BUTTON_CLASS + 'min-w-[150px] ml-4'} onClick={handleChooseFree}>
          {loading === 1 ? <Spinner /> : 'Cancel premium plan'}
          </button>
        </div>
      </div>

      :

      <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary bg-white">
        <p className="text-3xl pr-20">Upgrade to premium?</p>
        <p>You’ll be taken to the Stripe payment processing platform to complete your upgrade.</p>
        <p>You will have unlimited contact cards!</p>
        <div className="flex flex-row justify-end">
          <button className={BUTTON_CLASS_WHITE} onClick={handleCancel}>Cancel</button>
          <button className={BUTTON_CLASS + 'min-w-[150px] ml-4'} onClick={handleChoosePremium} data-turbolinks="false">
            {loading === 1 ? <Spinner /> : 'Upgrade to premium'}
            </button>
        </div>
      </div>


      }

      <ul>
        {errors.map((error, idx) => <li key={idx}>{error.message}</li>)}
      </ul>

    </>
    





  
    

  )

}

export default ChangePlan;