export const SET_LOADING = 'SET_LOADING';


export const setLoading = loadingInt => {
  return ({
    type: SET_LOADING,
    loadingInt
  })
};


export const getLoading = (state) => {
  if (!state.loading) return 0;
  return state.loading;
};


const loadingReducer = (state = false, action) => {
  switch (action.type) {
    case SET_LOADING:
      return action.loadingInt;
    default:
      return state;
  }
};

export default loadingReducer;