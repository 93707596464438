import { useState } from "react";
import { Modal } from "../context/Modal";
import ContactDelete from "./ContactDelete";
import {openInNewTab, shortenText} from "./Utils";
import emptyPic from "../assets/profile_pic_empty.svg";
import { BODY_BOLD, TEXT_SMALL } from "../constants";


const ContactCard = ({contact, setShowContactShow, snapshot, setContact}) => {

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [stopPropagation, setStopPropagation] = useState(false);
  const draggingStyleAddition = !snapshot.isDragging ? 'border-white' : 'border-success-green';

  const handleDelete = (e) => {
    e.preventDefault();
    if(e && e.stopPropagation) {
      e.stopPropagation();
    }
    setShowDeleteModal(true);
    setStopPropagation(true);
  }

  const handleShowContact = (e) => {
    if (!stopPropagation) {
    e.preventDefault();
    setContact(contact);
    setShowContactShow(true);
    }
  }


  return (

    <div id={`contact-card-${contact.id}`} className={`hover:border-brand-primary flex flex-col rounded-lg w-full bg-white p-3 mb-2 cursor-pointer h-[150px] justify-between border-solid border-2 ` + draggingStyleAddition} onClick={handleShowContact}>
      <div className="flex flex-row">
        <img className="rounded-full w-12 h-12 object-contain" src={contact.photoUrl ? contact.photoUrl : emptyPic} />
        <div className="flex flex-col ml-3">
          <p className={BODY_BOLD}>{shortenText(contact.name, 18)}</p>
          <p className={TEXT_SMALL}>{shortenText(contact.title, 23)}</p>
        </div>
      </div>
      <div className="flex flex-row justify-between">
        <p className={TEXT_SMALL}>{shortenText(contact.company, 33)}</p>
      </div>
      <div className="flex flex-row justify-between mt-3">
        <i className="fa-solid fa-trash-can text-xl hover:text-success-green" onClick={handleDelete}></i>
        {contact.linkedIn && <i onClick={e => openInNewTab(e, contact.linkedIn)} className="fa-solid fa-link text-xl hover:text-success-green"></i>}
      </div>
    {showDeleteModal && <Modal children={<ContactDelete setStopPropagation={setStopPropagation} setShowContactShow={setShowContactShow} setShowDeleteModal={setShowDeleteModal} contactId={contact.id}/>}/>}
    </div>

  )

}

export default ContactCard;