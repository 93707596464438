import LandingHeader from "./LandingHeader";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";

const DocumentPage = () => {

  const path = window.location.pathname;

  return (
    <div>
      <LandingHeader/>
      <div>
        {path === '/terms' ? <TermsOfUse/> : <PrivacyPolicy/>}
      </div>
    </div>
  )

}

export default DocumentPage;