import { useDispatch, useSelector } from "react-redux";
import { deleteInteraction } from "../store/interactions";
import { getLoading, setLoading } from "../store/loading";
import Spinner from "./Spinner";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE } from "../constants";
import { removeIdFromStateArray } from "./Utils";


const InteractionDelete = ({key, interaction, setShowInteractionDelete, setShowNewInteraction, setDataChanged}) => {

  const dispatch = useDispatch();
  const loading = useSelector(getLoading);

  const handleDeleteInteraction = async e => {
    e.preventDefault();  
    dispatch(setLoading(1));
    if (interaction.id) await dispatch(deleteInteraction(interaction.id));
    // TODO: error handling
    dispatch(setLoading(0));
    setShowInteractionDelete(false);
    setShowNewInteraction(false);
    removeIdFromStateArray(key, setDataChanged);
  }

  const handleCancel = e => {
    e.preventDefault();
    setShowInteractionDelete(false);
  }

  return (

    <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary bg-white">
      <p className="text-3xl pr-20">Discard Interaction Notes?</p>
      <p>All interaction information will be lost.</p>
      <p>The interaction will be removed from your timeline.</p>
      <div className="flex flex-row justify-end">
        <button className={BUTTON_CLASS_WHITE + 'm-3'} onClick={handleCancel}>Cancel</button>
        <button className={BUTTON_CLASS + "m-3 min-w-[150px]"} onClick={handleDeleteInteraction}>
          {loading == 1 ? <Spinner /> : 'Discard Interaction'}
          </button>
      </div>
    </div>

  )
}

export default InteractionDelete;