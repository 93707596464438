import SignupHeader from "./SignupHeader";
import emblem from '../assets/linkedin_logo.png';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, sendVerificationEmail } from "../store/session";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchUsers, getUserByDesiredEmail, getUserByEmail, getUsers } from "../store/users";
import toast from "react-hot-toast";
import { BODY, HEADER_3, sub2Med } from "../constants";

const EmailConfirmation = () => {

  const {email} = useParams();
  const lowerCaseEmail = email?.toLowerCase();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const targetUnverifiedUser = useSelector(getUserByEmail(lowerCaseEmail));
  const targetVerifiedUser = useSelector(getUserByDesiredEmail(lowerCaseEmail));
  const errorMessage = 'Could not send verification email. Please contact us or try again later. ';
  
  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const handleClickUpdateEmail = () => {
    if (currentUser && currentUser.emailVerified && currentUser.desiredEmail === lowerCaseEmail) {
      try {
        sendVerificationEmail(currentUser.id, true, lowerCaseEmail);
        toast.success('Verification email sent.');
      } catch (e) {
        toast.error(errorMessage)
      }
    } else if (!currentUser) {
      try {
        if (targetUnverifiedUser && !targetUnverifiedUser.emailVerified) {
          sendVerificationEmail(targetUnverifiedUser.id, false, lowerCaseEmail);
          toast.success('Verification email sent.');
        } else if (targetVerifiedUser) {
          sendVerificationEmail(targetVerifiedUser.id, true, lowerCaseEmail);
          toast.success('Verification email sent.');
        } else {
          toast.error(errorMessage + 'No verified user found.')
        }
      } catch (e) {
        toast.error(errorMessage)
      }
    } else {
      toast.error(errorMessage)
    }
  }

  if (!email) return (
    <div className="flex flex-col justify-center items-center">
      <h1>No email input</h1>
    </div>
  )


  return (
    
    <div className="flex flex-col justify-center items-center">
      <SignupHeader />
      <div className="flex flex-col lg:justify-center items-center lg:w-1/2 h-screen p-5 lg:p-0 text-center">
        { 
          (currentUser && currentUser.emailVerified ) && <p className="text-xl font-semibold mb-6">Email address updated!</p>
        }
        <p className={HEADER_3}>Confirm your email address to log in.</p>
        <p className={sub2Med}>We sent a confirmation email to <span className="text-success-green">{lowerCaseEmail}.</span></p>
        <p className={BODY + "mb-5"}>It may be in your junk or spam folder.</p>
        { window.innerWidth < 1024 && <p className={BODY + 'mb-4'}>UNetwrk is not optimized for mobile. We suggest you log in from a desktop or laptop computer.</p>}
        <p onClick={handleClickUpdateEmail} className="text-link-blue underline cursor-pointer mb-5">Resend verification email</p>
        <div className="px-8">       
          <img src={emblem} alt="Unetwrk Logo" />
        </div>                   
      </div>
    </div>

  )
}

export default EmailConfirmation;