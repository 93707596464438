import { useState } from "react";
import { useSelector } from "react-redux";
import { getContactInteractions } from "../store/interactions";
import InteractionShow from "./InteractionShow";
import { BUTTON_CLASS } from "../constants";


const InteractionIndex = ({contact, setDataChanged}) => {

  const [showNewInteraction, setShowNewInteraction] = useState(false);
  const interactions = useSelector(getContactInteractions(contact.id)).sort((a, b) => {
    return new Date(b.dateContacted) - new Date(a.dateContacted);
  });

  return (

    <div className="interaction-index flex flex-col items-start w-full p-3 overflow-auto h-full">
      <div className="flex flex-col items-center justify-center w-full" >
        <button className={BUTTON_CLASS + "mb-5"} onClick={e => setShowNewInteraction(true)}>+ Add interaction</button>
        {(interactions.length || showNewInteraction) && 
        <div className="flex flex-col items-start w-full bg-green-100">
          {showNewInteraction && <InteractionShow setShowNewInteraction={setShowNewInteraction} contact={contact} startOpen={true} setDataChanged={setDataChanged}/>}
          {interactions.map((interaction) => (
            <InteractionShow key={interaction.id} interaction={interaction} contact={contact} setShowNewInteraction={setShowNewInteraction} setDataChanged={setDataChanged}/>
          ))}
        </div>
        }
      </div>
    </div>

  )

}

export default InteractionIndex;