import SignupHeader from "./SignupHeader";
import emblem from '../assets/linkedin_logo.png';
import toast from "react-hot-toast";
import { forgotPassCall } from "./apiCalls";
import { useLocation } from "react-router-dom";


const ForgotPasswordEmailSent = () => {

  const location = useLocation();
  const email = location.state.email;
  console.log('email', email);
  const lowerCaseEmail = email?.toLowerCase();

  const handleClickResendEmail = async () => {
    try {
      await forgotPassCall(email);
      toast.success('Email sent if the email address is associated with a Unetwrk account.');
    }
    catch (resp) {
      const error = await resp.json();
      toast.error(error.message)
    }
  }

  if (!email) return (
    <div className="flex flex-col justify-center items-center">
      <h1>No email input</h1>
    </div>
  )


  return (
    
    <div className="flex flex-col justify-center items-center">
      <SignupHeader />
      <div className="flex flex-col justify-center items-center w-1/2 h-screen">

        <p className="text-lg font-medium mb-2 text-center">If the email address is associated with a Unetwrk account, we sent a confirmation email to <span className="text-success-green">{lowerCaseEmail}.</span></p>

        <p className="text-2xl font-medium mb-2">Follow the instructions in the email.</p>
        <p className="text-lg mb-6">If you don't see it, check your junk or spam folder.</p>
        <p onClick={handleClickResendEmail} className="text-link-blue underline cursor-pointer">Resend email</p>
        <div className="p-3">       
          <img src={emblem} alt="Unetwrk Logo" />
        </div>                   
      </div>
    </div>

  )
}

export default ForgotPasswordEmailSent;