import csrfFetch from './csrf.js';
import { createTier } from './tiers.js';

const ADD_CURRENT_USER = 'ADD_CURRENT_USER';
const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';

export const getCurrentUser = (state = {}) => {
  if (state.session && state.session.user) {
    return state.session.user;
  } else {
    return null;
  }
}

export const signup = inputs => async dispatch => {
  let {email, name, password} = inputs;
  let res = await csrfFetch('/api/users', {
    method: 'POST',
    body: JSON.stringify({
      email,
      name,
      password
    })
  })
  if (res.ok) {
    let data = await res.json();

    try {
      sendVerificationEmail(data.id);
    } catch {
      alert('We are unable to sign you up. Please try again. If the problem persists, please contact us.');
      return;
    }


    const tierNames = [
      'New', 'Colleagues', 'Friends', 'Potential Referrals'
    ];
    tierNames.forEach(async (tierName, index) => {
      let tier = {
        name: tierName,
        position: index,
        user_id: data.id
      };
      await dispatch(createTier(tier));
    });
  } else {
    alert('We are unable to sign you up. Please try again. If the problem persists, please contact us.');
  }
};

export const sendVerificationEmail = async (userId, isUpdate = false, email) => {
  let res = await csrfFetch('/api/send-email-verification', {
    method: 'POST',
    body: JSON.stringify({
      user_id: userId,
      is_update: isUpdate,
      desired_email: email
    })
  });
  return res;
}

export const logout = () => async dispatch => {
  const res = await csrfFetch(`/api/session`, {
    method: 'DELETE'
  });

  if (res.ok) {
    storeCurrentUser(null);
    dispatch(removeCurrentUser());
    return res;
  }
}

export const addCurrentUser = (user) => {
  return ({
    type: ADD_CURRENT_USER,
    user: user
  });
};

export const removeCurrentUser = () => {
  return ({
    type: REMOVE_CURRENT_USER,
  });
};

export const restoreSession = () => async dispatch => {
  let res = await csrfFetch('/api/session');
  storeCSRFToken(res);
  let data = await res.json();
  if (data.id) {
    storeCurrentUser(data);
    dispatch(addCurrentUser(data));
  } else {
    storeCurrentUser(null);
    dispatch(removeCurrentUser());
  }
  return res;
}

export const storeCurrentUser = (user) => {
  if (user) {
    localStorage.setItem('currentUser',JSON.stringify(user));
  } else {
    localStorage.removeItem("currentUser");
  }
};

export const storeCSRFToken = (res) => {
  const token = res.headers.get('X-CSRF-Token');
  if (token) localStorage.setItem('X-CSRF-Token', token);
};

export const login = (user) => async (dispatch) => {
  const { email, password } = user;
  let res = await csrfFetch('/api/session', {
    method: 'POST',
    body: JSON.stringify({
      email,
      password
    })
  });
  if (res.ok) {
    let data = await res.json();
    storeCurrentUser(data)
    dispatch(addCurrentUser(data));
    return res;
  } else {
    return ({error: 'We are unable to log you in. Please try again. If the problem persists, contact an administrator.'})
  }
}

let user = JSON.parse(localStorage.getItem("currentUser"));
const initialState = { user: user}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CURRENT_USER:
      return { user: action.user }
    case REMOVE_CURRENT_USER:
      return { user: null }
    default:
      return state;
  }
}

export default sessionReducer;