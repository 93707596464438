import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentUser } from './store/session';
import Dashboard from './components_unetwrk/Dashboard';
import Signup from './components_unetwrk/Signup';
import Login from './components_unetwrk/Login';
import EmailConfirmation from './components_unetwrk/EmailConfirmation';
import ChoosePlan from './components_unetwrk/ChoosePlan';
import LimitReached from './components_unetwrk/LimitReached';
import Settings from './components_unetwrk/Settings';
import PlanUpdated from './components_unetwrk/PlanUpdated';
import LandingPage from './components_unetwrk/LandingPage';
import ContactShow from './components_unetwrk/ContactShow';
import PasswordUpdated from './components_unetwrk/PasswordUpdated';
import ForgotPassword from './components_unetwrk/ForgotPassword';
import ResetPassword from './components_unetwrk/ResetPassword';
import ForgotPasswordEmailSent from './components_unetwrk/ForgotPasswordEmailSent';
import DocumentPage from './components_unetwrk/DocumentPage';
import Account from './components_unetwrk/Account';


function App() {
  
  const currentUser = useSelector(getCurrentUser);


  return (
    <>
      {currentUser ? 
      <Switch>
        {!currentUser.emailVerified &&
        <>
          <Route path="">
            <Redirect to={`/email-confirmation/${currentUser.email}`}/>
          </Route>
          <Route path="/email-confirmation/:email">
            <EmailConfirmation/>
          </Route>  
        </>
        }
        {!currentUser.plan &&
        <Route path="">
          <ChoosePlan/>
        </Route>
        }
        <Route exact path="/kanban">
          <Dashboard/>
        </Route>
        <Route path="/kanban/:contactId">
          <Dashboard/>
        </Route>
        <Route path="/add-contact">
          <ContactShow/>
        </Route>
        <Route path="/signup">
          <Redirect to="/kanban"/>
        </Route>
        <Route path="/login">
          <Redirect to="/kanban"/>
        </Route>
        <Route path="/limit-reached">
          <LimitReached/>
        </Route>     
        <Route path="/settings">
          <Settings/>
        </Route> 
        <Route path="/password-updated">
          <PasswordUpdated/>
        </Route>          
        <Route path="/email-confirmation/:email">
          <EmailConfirmation/>
        </Route>        
        <Route path="/account">
          <Account/>
        </Route>   
        <Route path="/reset-password/:email/:token">
          <ResetPassword/>
        </Route>  
        <Route path="/plan-updated/:status">
          <PlanUpdated/>
        </Route>  
        <Route exact path="/forgot-password">
          <ForgotPassword/>
        </Route>   
        <Route exact path="/forgot-password-email-sent">
          <ForgotPasswordEmailSent/>
        </Route>                   
        <Route path="">
          <Redirect to="/kanban"/>
        </Route>
      </Switch>
          : 
      <Switch>
        <Route path="/terms">
          <DocumentPage/>
        </Route>          
        <Route path="/privacy-policy">
          <DocumentPage/>
        </Route>               
        <Route path="/password-updated">
          <PasswordUpdated/>
        </Route>         
        <Route exact path="/forgot-password">
          <ForgotPassword/>
        </Route>   
        <Route exact path="/forgot-password-email-sent">
          <ForgotPasswordEmailSent/>
        </Route>  
        <Route path="/reset-password/:email/:token">
          <ResetPassword/>
        </Route>            
        <Route path="/signup">
          <Signup/>
        </Route>
        <Route path="/login">
          <Login/>
        </Route>    
        <Route path="/email-confirmation/:email">
          <EmailConfirmation/>
        </Route>
        <Route path="/">
          <LandingPage/>
        </Route>
        <Route path="">
          <Redirect to="/"/>
        </Route>
      </Switch>
        
      }
    </>
  );
  
}

export default App;
