import csrfFetch from "./csrf";
import { restoreSession } from "./session";

export const ADD_USER = 'ADD_USER';
export const ADD_USERS = 'ADD_USERS';
export const REMOVE_USER = 'REMOVE_USER';


const addUser = user => {
  return ({
    type: ADD_USER,
    user
  })
};

const addUsers = users => {
  return ({
    type: ADD_USERS,
    users
  })
};

const removeUser = (userId) => ({
  type: REMOVE_USER,
  userId
});

export const getUsers = (state) => {
  if (!state.users) return [];
  return Object.values(state.users);
};

export const getUser = userId => (state) => {
  if (!state.users) return null;
  return state.users[userId];
}

export const getUserByEmail = email => (state) => {
  if (!state.users) return null;
  return Object.values(state.users).find(user => user.email === email);
}

export const getUserByDesiredEmail = email => (state) => {
  if (!state.users) return null;
  return Object.values(state.users).find(user => user.desiredEmail === email);
}

export const fetchUser = userId => async dispatch => {
  const res = await fetch(`/api/users/${userId}`);

  if(res.ok) {
    const user = await res.json();
    dispatch(addUser(user))
  };
};

export const fetchUsers = () => async dispatch => {
  const res = await fetch(`/api/users/`);

  if(res.ok) {
    const users = await res.json();
    dispatch(addUsers(users))
  };
};

export const updateUser = (user) => async dispatch => {
  const res = await csrfFetch(`/api/users/${user.id}`, {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(user)
  });

  if(res.ok) {
    const revisedUser = await res.json();
    dispatch(addUser(revisedUser));
    dispatch(restoreSession());
    return revisedUser;
  };
  return null;
};

export const deleteUser = (userId) => async dispatch => {
  const res = await csrfFetch(`/api/users/${userId}`, {
    method: 'DELETE'
  });

  if (res.ok) {
    dispatch(removeUser(userId));
    dispatch(restoreSession());
  };
};

const usersReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_USERS:
      return action.users;
    case ADD_USER:
      return {...state, [action.user.id]: action.user}
    case REMOVE_USER:
      let newState = {...state};
      delete newState[action.userId]
      return newState;    
    default:
      return state;
  }
};

export default usersReducer;