import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContact, getUserContacts, updateContact } from "../store/contacts";
import { checkErrors, shortenText } from "./Utils";
import toast from "react-hot-toast";
import { DROPDOWN_ITEM } from "../constants";


const ColumnChoiceDropdown = ({tiers = [], setColumnOrder, contactId}) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.session.user);
  const contacts = useSelector(getUserContacts(currentUser.id));
  const contact = useSelector(getContact(contactId));

  const matchTier = (columnOrder) => {
    if (columnOrder != undefined) {
      const matchedTier = tiers.find(tier => tier.position === columnOrder);
      return matchedTier;
    } else {
      return {name: "Select Tier"}
    }
  };
  
  const [errors, setErrors] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [targetTier, setTargetTier] = useState(matchTier(contact?.columnOrder));

  const handleChooseTier = async e => {
    e.preventDefault();
    let currentErrors = [];
    const targetPosition = parseInt(e.target.getAttribute('position'));
    const newTier = matchTier(targetPosition)
    setTargetTier(newTier);
    const newTierContactLength = contacts.filter(contact => contact.columnOrder === newTier.position).length;
    let newContact = new FormData();
    newContact.append('contact[id]', contact.id);
    newContact.append('contact[column_order]', targetPosition);
    newContact.append('contact[vertical_order]', newTierContactLength);
    await dispatch(updateContact(newContact))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setErrors);
    });
    
    if (!currentErrors.length) {
      toast.success("Column updated successfully.")
      setColumnOrder(targetPosition);
      setTargetTier(matchTier(targetPosition));
      setShowMenu(false);
    } else {
      alert([errors[0].message]);
      toast.error("Column update failed. Please try again.")
    }
  }

  return (
    <div onClick={e => setShowMenu(!showMenu)} tabIndex="1" onBlur={e => setShowMenu(false)} className="drop-shadow-lg bg-white border-none h-10 w-full cursor-pointer z-50 rounded">
      <div className='flex flex-row w-full justify-between p-3'>
        <h4>{targetTier ? shortenText(targetTier.name, 30) : 'Placeholder'}</h4>
        <i className="fa-solid fa-angle-down"></i>
      </div>
      <ul className='dropdown-list z-50 w-full bg-white rounded'>
      {showMenu && tiers.map(tier => (
          <li key={tier.id} position={tier.position} className={DROPDOWN_ITEM} onClick={handleChooseTier}>{shortenText(tier.name, 30)}</li>
      ))}
      </ul>      
    </div>

  );
};

export default ColumnChoiceDropdown;