const PrivacyPolicy = () => {

  return (

    <div className="flex flex-col items-center justify-center p-20">
      <div>
        <p className="text-4xl font-bold">Privacy Policy</p>
        <p className="mb-8">Last updated on June 16th, 2024</p>
      </div>
      <div className="w-[800px]">
        <p className="mb-8">This privacy policy describes how UNetwrk (“UNetwrk”, "we", "us", or "our") collects, uses, and discloses your information when you visit unetwrk.com (the "Service").</p>
        <p className="font-bold">Information collected</p>
        <p className="mb-8">We may collect several types of information to provide and improve our Service for you.</p>
        <p className="font-bold">Personal data</p>
        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
        <ul className="mb-8 list-disc list-inside">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Cookies and Usage Data</li>
        </ul>
        <p className="font-bold">Usa of data</p>
        <p>UNetwrk uses the collected data for various purposes:</p>
        <ul className="mb-8 list-disc list-inside">
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our Service</li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
      </div>
    </div>

  )

}

export default PrivacyPolicy;