import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SignupHeader from "./SignupHeader";
import emblem from '../assets/linkedin_logo.png';
import { BUTTON_CLASS } from "../constants";


const PasswordUpdated = () => {

  const history = useHistory();

  const handleClickLogIn = () => {
    history.push('/login');
  }

  return (

  <div className="flex flex-col justify-center items-center">
    <SignupHeader />
    <div className="flex flex-col justify-center items-center w-1/2 h-screen">
      <p className="text-4xl font-semibold mb-6">Password updated!</p>
      <p className="text-3xl font-medium mb-2">Please log in using your new password.</p>
      <button onClick={handleClickLogIn} className={BUTTON_CLASS + 'm-6'}>Log in</button>
      <div className="p-3">       
        <img src={emblem} alt="Unetwrk Logo" />
      </div>                   
    </div>
  </div> 

  )


}


export default PasswordUpdated;