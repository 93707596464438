import { useDispatch } from "react-redux";
import { logout } from "../store/session";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE } from "../constants";


const AccountDelete = ({setShowLogoutModal}) => {

  const dispatch = useDispatch();

  const handleLogout = e => {
    dispatch(logout());
    setShowLogoutModal(false);
  }

  const handleCancel = e => {
    setShowLogoutModal(false);
  }

  return (

    <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary bg-white">
      <p className="text-3xl pr-20">Log out?</p>
      <p>Great job growing and maintaining your network!</p>
      <p>You deserve a break.</p>
      <div className="flex flex-row justify-end">
        <button className={BUTTON_CLASS_WHITE + 'm-3'} onClick={handleCancel}>Cancel</button>
        <button className={BUTTON_CLASS + 'm-3'} onClick={handleLogout}>Log out</button>
      </div>
    </div>

  )

}

export default AccountDelete;