import { TERMS_SERVICE } from "../constants";

const TermsOfUse = () => {

  const terms = TERMS_SERVICE;

  return (

    <div className="flex flex-col items-center justify-center p-20">
      <div className="flex flex-col items-center justify-center">
        <p className="text-4xl font-bold">Terms of use</p>
        <p className="mb-8">Last updated on June 16th, 2024</p>
      </div>
      <div>
        <ol>
          {
            terms.map((term, idx) => (
              <li key={idx} className="mb-8">
                <p className="font-bold">{term.title}</p>
                <ul>
                  {
                    term.content.map((point, idx) => (
                      <li className="list-disc list-inside" key={idx}>{point}</li>
                    ))
                  }
                </ul>
                <p>{term.point}</p>
              </li>
            ))
          }
        </ol>
        <p>By using our service, you acknowledge that you have read and understood these terms and agree to be bound by them.</p>
      </div>
    </div>

  )

}

export default TermsOfUse;