import TimelineUnit from './TimelineUnit';
import { useSelector } from 'react-redux';
import { getContactInteractions } from '../store/interactions';
import { prettyDate } from './Utils';
import { getContact } from '../store/contacts';

const Timeline = ({contactId}) => {

  const interactions = useSelector(getContactInteractions(contactId));
  const contact = useSelector(getContact(contactId));

  const dateContacted = contact?.dateConnected ? prettyDate(contact?.dateConnected, true) : prettyDate(contact?.createdAt);

  const firstConnectionEventInfo = {
    contactMethod: `First connected with ${contact?.name}`,
    dateContacted,
    first: true
  }

  const sortEvents = (events) => {
    return events.sort((a, b) => {
      return new Date(a.dateContacted) - new Date(b.dateContacted);
    })
  }

  const filterAndSortInteractions = (interactions) => {
    return sortEvents(interactions.filter(interaction => {
      return interaction.contactMethod && interaction.dateContacted
    
    }))
  }

  return (
    <div className='bg-pale-green p-2 rounded h-full w-[350px]'>
      <div className='bg-white rounded dropshadow p-2 h-full'>
        <div className="border-solid border-green-900 border-b-2 mb-6">
          <p className='text-lg'>Timeline</p>
        </div>
        <div className='overflow-auto max-h-[90%]'>
          <TimelineUnit eventInfo={firstConnectionEventInfo}/>
          {filterAndSortInteractions(interactions).map(interaction => <TimelineUnit key={interaction.id} eventInfo={interaction}/>)}
        </div>
      </div>
    </div>
    
  )

}

export default Timeline;