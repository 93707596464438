import { BUTTON_CLASS, BUTTON_CLASS_WHITE } from "../constants";


const CloseContact = ({setShowCloseContactModal, setShowContactShow}) => {

  const handleCloseContact = async (e) => {
    e.preventDefault();
    setShowCloseContactModal(false);
    setShowContactShow(false);
  }

  const handleCancel = e => {
    e.preventDefault();
    setShowCloseContactModal(false);
  }

  return (

    <div className="flex flex-col justify-start bg-slate-200 p-5 h-full min-w-[500px] rounded border-2 border-brand-primary bg-white">
      <p className="text-3xl pr-20">Close card without saving?</p>
      <p>You input information but did not save it.</p>
      <p>Information you input will not be saved.</p>
      <div className="flex flex-row justify-end">
        <button className={BUTTON_CLASS_WHITE + 'm-3'} onClick={handleCancel}>Cancel</button>
        <button className={BUTTON_CLASS + 'm-3 min-w-[150px]'} onClick={handleCloseContact}>Close card
        </button>
      </div>
    </div>

  )

}

export default CloseContact;