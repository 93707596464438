import photoChevron from '../assets/photoChevron.png';
import logo5 from '../assets/logo5.svg';
import logo3 from '../assets/logo3.svg';
import logo4 from '../assets/logo4.svg';
import shot1 from '../assets/shot1.jpg';
import shot2 from '../assets/shot2.jpg';
import shot3 from '../assets/shot3.jpg';
import shot4 from '../assets/shot4.jpg';
import { useHistory } from 'react-router-dom';
import LandingHeader from './LandingHeader';
import Footer from './Footer';
import { BODY, BODY_BOLD, BUTTON_CLASS, HEADER_1, HEADER_2, HEADER_3, SUBTITLE, SUBTITLE_BOLD } from '../constants';
import MobileLandingHeader from './MobileLandingHeader';


const LandingPage = () => {

  const history = useHistory();

  const handleGoSignup = (e) => {
    history.push('/signup');
  }


  return (

    <div className="flex flex-col items-center justify-center overflow-x-hidden px-5 lg:px-0">
      { window.innerWidth < 1024 ? <MobileLandingHeader/> : <LandingHeader/> }
      <div className='flex flex-col lg:p-10 mb-6 w-full text-start'>
        <div className='flex flex-col items-center lg:flex-row w-full justify-evenly lg:m-32 lg:mt-24 mt-12'>
          <div className='flex flex-col lg:h-[400px] w-full lg:w-[450px] '>
            <p className={HEADER_1}>You network?</p>
            <p className={HEADER_1}>You should!</p>
            <p className={SUBTITLE + ' text-[18px] mb-[16px]'}>UNetwrk makes it easy to grow, organize, & maintain your network.</p>
            <p className={BODY + 'mb-[24px]'}>Organize contacts and catalog your communication to boost referrals and progress your career.</p>
            <button onClick={handleGoSignup} className={BUTTON_CLASS + 'w-full lg:w-[180px]'}>Get started</button>
          </div>
          <div className='lg:w-[900px] lg:h-[400px] flex justify-center items-center p-8 lg:p-0 mb-6'>
            <img className='object-contain h-full' src={photoChevron} alt='photoChevron' />
          </div>
        </div>
        <div className='flex flex-col items-center w-full text-center'>
          <p className={HEADER_2 + 'mb-2'}>Why is networking important?</p>
          <p className={HEADER_3 + 'mb-8'}>The numbers tell the story.</p>
          <div className='flex flex-col lg:flex-row justify-evenly w-full'>
            <div className='flex flex-col items-center lg:w-[340px] mb-8'>
              <img className='mb-4' src={logo3} alt='logo3' />
              <p className={SUBTITLE}>of employers rate referrals as the best ROI for sourcing talent.</p>
            </div>
            <div className='flex flex-col items-center lg:w-[340px] mb-8'>
              <img className='mb-4' src={logo5} alt='logo5' />
              <div className='flex flex-col items-center'>
                <p className={SUBTITLE}>of my net worth comes from meeting people.</p>
                <p className={SUBTITLE}>-Noah Kagan</p>
              </div>
            </div>
            <div className='flex flex-col items-center lg:w-[340px]'>
              <img className='mb-4' src={logo4} alt='logo4' />
              <p className={SUBTITLE}>of hires originated from referrals in 2022.</p>
            </div>                    
          </div>
        </div>
      </div>
      <div className='flex flex-col bg-pale-green p-6 lg:p-12 w-full items-center pb-24 mb-24 w-screen text-center'>
        <p className={HEADER_2 + 'mb-4'}>Networking made simple</p>
        <p className={HEADER_3}>You're more likely to maintain your network if it's easy.</p>
        <div className='lg:h-[325px] lg:w-[565px] w-full h-[200px] my-12'>
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/p7aM7-lcaB0?si=3Uoq9bkqeQ5Ezl1a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div className='flex flex-col-reverse lg:flex-row justify-evenly items-center lg:mb-20 mb-16'>
          <img className='w-[530px] lg:mr-12' src={shot1} alt='shot1' />
          <div className='flex flex-col lg:w-1/3 mb-6' >
            <p className={HEADER_3}>Chrome extension</p>
            <p>Use the Chrome extension to add contacts from LinkedIn. The information is automatically saved to your contact card board.</p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row flex-row justify-evenly items-center lg:mb-20 mb-16'>
          <div className='flex flex-col lg:w-1/3 lg:mr-12 mb-6' >
            <p className={HEADER_3}>Contact card board</p>
            <p>Organize contact cards in columns. Use the preset column headers or edit them to your preferences. </p>
          </div>
          <img className='w-[530px]' src={shot2} alt='shot2' />
        </div>
        <div className='flex flex-col-reverse lg:flex-row flex-row justify-evenly items-center lg:mb-20 mb-16'>
          <img className='w-[530px] lg:mr-12' src={shot3} alt='shot3' />
          <div className='flex flex-col lg:w-1/3 mb-6' >
            <p className={HEADER_3}>Contact information</p>
            <p>Contact cards have all the important information, including a clickable link to the your contact’s LinkedIn profile.</p>
          </div>
        </div> 
        <div className='flex flex-col lg:flex-row flex-row justify-evenly items-center'>
          <div className='flex flex-col lg:w-1/3 lg:mr-12 mb-6' >
            <p className={HEADER_3}>Interaction notes</p>
            <p>Keep notes on your interactions and set a date to be notified when you’d like to contact them next.</p>
          </div>
          <img className='w-[530px]' src={shot4} alt='shot4' />
        </div>       
      </div>
      <div className='flex flex-col items-center p-10 pt-0 lg:min-w-[600px] text-center'>
        <p className={HEADER_2}>Maintain your network effectively</p>
        <p className={HEADER_3}>Get started today.</p>
        <div className='flex flex-col lg:flex-row justify-between w-full mt-8'>
          <div className='flex flex-col bg-pale-green p-3 min-w-[260px] lg:w-2/5 rounded mb-6 lg:mb-0'>
            <div className='flex flex-col items-center justify-center bg-white mb-3 rounded drop-shadow p-3'>
              <p className={SUBTITLE}>Free</p>
              <p className={BODY_BOLD}>$0/month</p>
            </div>
            <div className='flex flex-col items-start justify-center bg-white rounded drop-shadow p-3'>
              <p><span className={BODY}>10</span> contact cards</p>
              <p>Chrome extension</p>
            </div>
          </div>
          <div className='flex flex-col bg-pale-green p-3 min-w-[260px] lg:w-2/5 rounded lg:ml-12'>
            <div className='flex flex-col items-center justify-center bg-white mb-3 rounded drop-shadow p-3'>
              <p className={SUBTITLE_BOLD}>Premium</p>
              <p className={BODY_BOLD}>$10/month</p>
            </div>
            <div className='flex flex-col items-start justify-center bg-white rounded drop-shadow p-3'>
              <p><span className={BODY_BOLD}>Unlimited</span> contact cards</p>
              <p>Chrome extension</p>
            </div>
          </div>
        </div>
        <button className={BUTTON_CLASS + 'mt-6 mb-24 w-full'} onClick={handleGoSignup}>Get started</button>
      </div>
      <Footer/>
    </div>

  )

}

export default LandingPage;