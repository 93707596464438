import React, { useEffect, useState } from 'react';
import { updateContact } from '../store/contacts';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '../store/session';
import {checkErrors, updateFlagAndVariable, validateUrl} from './Utils';
import { getLoading, setLoading } from '../store/loading';
import Spinner from './Spinner';
import toast from 'react-hot-toast';
import { openInNewTab } from './Utils';
import { BUTTON_CLASS, INPUT_CLASS } from '../constants';

const blankContact = {
  name:  "",
  company: "",
  title: "",
  connectionDescription: "",
  dateConnected: "",
  email: "",
  phoneNumber: "",
  userId: "",
  linkedIn: "",
  columnOrder: 0
}

const ContactUpdate = ({ contact = blankContact, columnOrder = 0, setDataChanged}) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [name, setName] = useState(contact.name ? contact.name : '');
  const [company, setCompany] = useState(contact.company ? contact.company : '');
  const [title, setTitle] = useState(contact.title ? contact.title : '');
  const [photo, setPhoto] = useState('');
  const [connectionDescription, setConnectionDescription] = useState(contact.connectionDescription);
  const [dateConnected, setDateConnected] = useState(contact.dateConnected ? contact.dateConnected.slice(0, 10) : '');
  const [email, setEmail] = useState(contact.email ? contact.email : '');
  const [phoneNumber, setPhoneNumber] = useState(contact.phoneNumber ? contact.phoneNumber : '');
  const [linkedIn, setLinkedIn] = useState(contact.linkedIn ? contact.linkedIn : '');
  const [editUrl, setEditUrl] = useState(false);
  const [errors, setErrors] = useState([]);
  const loading = useSelector(getLoading);

  const validatePayload = (payload) => {
    let errors = [];
    if (payload.name.length < 1) errors.push("Name cannot be empty.");
    return errors;
  }

  const handleSubmitContact = async (e) => {
    e.preventDefault();
    dispatch(setLoading(1));
    setErrors([]);

    const payload = {
      name,
      company,
      title,
      connection_description: connectionDescription,
      date_connected: dateConnected,
      email,
      phone_number: phoneNumber,
      user_id: currentUser.id,
      linked_in: linkedIn,
      column_order: columnOrder,
      photo
    }

    const payloadFormData = new FormData();
    payloadFormData.append('contact[name]', name);
    payloadFormData.append('contact[company]', company);
    payloadFormData.append('contact[title]', title);
    payloadFormData.append('contact[connection_description]', connectionDescription);
    payloadFormData.append('contact[date_connected]', dateConnected);
    payloadFormData.append('contact[email]', email);
    payloadFormData.append('contact[phone_number]', phoneNumber);
    payloadFormData.append('contact[user_id]', currentUser.id);
    payloadFormData.append('contact[linked_in]', linkedIn);
    payloadFormData.append('contact[column_order]', columnOrder);
    payloadFormData.append('contact[id]', contact.id);
    if (photo) payloadFormData.append('contact[photo]', photo);
    
    let currentErrors = validatePayload(payload);
    setErrors(currentErrors);
    if (currentErrors.length > 0) {
      dispatch(setLoading(0));
      return;
    } else {
      setDataChanged(false);
      await dispatch(updateContact(payloadFormData)).catch(async (res) => {
        currentErrors = await checkErrors(res, setErrors, currentErrors);
      });
      dispatch(setLoading(0));
      toast.success('Contact information saved.')
    }
  }

  const linkEditInput = document.getElementById('link-edit-input');

  const handleEditUrl = async (e) => {
    e.preventDefault();
    await setEditUrl_(true);
    linkEditInput.focus();
  }

  const setEditUrl_ = () => {
    setEditUrl(true);
  }

  const handleClickUrl = (e) => {
    if (linkedIn && !editUrl) {
      console.log('clicked');
      if (validateUrl(linkedIn)) {
        openInNewTab(e, linkedIn);
      } else {
        toast.error('Invalid URL');
      }
    }
  }

  const handleCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(email);
    toast.success('Email copied to clipboard.')
  }

  
  return (
    <form className='flex flex-col justify-between w-full p-5 pt-0 items-end'>
      <div className='flex flex-row justify-between w-full p-5 pt-0 pl-0'>
        <div className='flex flex-col w-1/2 p-3 pl-0'>
          <label htmlFor="name">Name <span className='italic'>{`(required)`}</span></label>
          <input className={INPUT_CLASS + 'mb-5'} type="text" id="name" value={name} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setName)} />
          <label htmlFor="company">Company</label>
          <input className={INPUT_CLASS + 'mb-5'} type="text" value={company} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setCompany)} />
          <label htmlFor="phoneNumber">Phone Number</label>
          <input className={INPUT_CLASS + 'mb-5'} type="text" value={phoneNumber} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setPhoneNumber)} />
          <label htmlFor="connectionDescription">Connection Description</label>
          <input className={INPUT_CLASS + 'mb-5'} type="text" value={connectionDescription} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setConnectionDescription)} />
        </div>
        <div className='flex flex-col  w-1/2 p-3 pr-0'>
          <label htmlFor="title">Title</label>
          <input className={INPUT_CLASS + 'mb-5'} type="text" value={title} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setTitle)} />
          <label htmlFor="email">LinkedIn Profile URL</label>
          <div className='flex flex-row items-center justify-end w-full mb-5 relative'>
            {!editUrl && <div onClick={handleClickUrl} className="w-5/6 h-10 absolute left-0 cursor-pointer z-10"></div>}
            <input id="link-edit-input" disabled={!editUrl} onBlur={e => setEditUrl(false)} className={!editUrl ? ' pl-2 pr-8 text-ellipsis drop-shadow bg-white border-none h-10 w-full text-link-blue underline rounded' : 'pl-2 pr-8 drop-shadow bg-white border-none h-10 rounded w-full'} type="text" value={linkedIn} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setLinkedIn)} />
            <i onClick={handleEditUrl} className="fa-solid fa-pencil cursor-pointer absolute mr-3 z-100"></i>
          </div>
          <label htmlFor="email">Email</label>
          <div className='flex flex-row items-center justify-end w-full'>
            <input className={'w-full mb-2' + INPUT_CLASS + 'mb-5'} type="text" value={email} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setEmail)} />
            <i onClick={handleCopy} className="fa-regular fa-copy cursor-pointer absolute mr-3 mb-4"></i>
          </div>
          <label htmlFor="dateConnected">Date Connected</label>
          <input className={INPUT_CLASS + 'mb-5 w-1/2'} type="date" value={dateConnected} onChange={(e) => updateFlagAndVariable(e, setDataChanged, setDateConnected)} />
        </div>
      </div>
      <div className='flex flex-col w-full items-end p-5 pt-0'>
        <button className={BUTTON_CLASS + 'w-1/2 mr-3'} onClick={handleSubmitContact}>
          {loading == 1 ? <Spinner/> : 'Save Contact Information'}
        </button>
      </div>
    </form>
  );

}

export default ContactUpdate;