import { prettyDate } from "./Utils";

const TimelineUnit = ({eventInfo}) => {

  return (
    <div className="relative border-solid border-brand-primary border-l-2 ml-1 pb-3 m-1">
      <div className="absolute top-[-4px] left-[-5px] rounded-full w-[8px] h-[8px] bg-brand-primary z-50"/>
      <div className="pl-2">
        <p>{eventInfo.first ? `${eventInfo.contactMethod}` : `Contacted via ${eventInfo.contactMethod}`}</p>
        <p>{prettyDate(eventInfo.dateContacted, true)}</p>
      </div>
    </div>
  )

}

export default TimelineUnit;