import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import configureStore from './store';
import csrfFetch from './store/csrf';
import * as sessionActions from './store/session';
import { ModalProvider } from "./context/Modal";
import {createRoot} from 'react-dom/client';
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import check from "./assets/check.svg";
import exclamation from "./assets/exclamation.svg"


const store = configureStore();

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
  window.csrfFetch = csrfFetch;
  window.sessionActions = sessionActions;
}

const checkImage = <img src={check}></img>
const exclamationImage = <img src={exclamation}></img>

const root = createRoot(document.getElementById("root"));

const renderApplicaton = () => {
  return root.render(
    <React.StrictMode>
      <ModalProvider>
        <Provider store={store}>
          <BrowserRouter>
            <Toaster 
            position={'top-center'}
            toastOptions={{
              // Define default options
              duration: 5000,
              style: {
                width: '1000px',
              },
              success: {
                icon: checkImage,
                style: {
                  color: 'green',
                  border: '2px solid green',
                },
              },
              error: {
                icon: exclamationImage,
                style: {
                  color: 'red',
                  border: '2px solid red',
                },
              },
              // Default options for specific types
            }}
            >
              {(t) => (
                <ToastBar toast={t}>
                  {({ icon, message }) => (
                    <>
                      {icon}
                      {message}
                      {t.type !== 'loading' && (
                        <button onClick={() => toast.dismiss(t.id)}>X</button>
                      )}
                    </>
                  )}
                </ToastBar>
              )}
            </Toaster>
            <App />
          </BrowserRouter>
        </Provider>
      </ModalProvider>
    </React.StrictMode>
  );
}

store.dispatch(sessionActions.restoreSession()).then(renderApplicaton);

// if(localStorage.getItem('X-CSRF-Token') === null || localStorage.getItem('currentUser') === null){
//   store.dispatch(sessionActions.restoreSession()).then(renderApplicaton);
// } else {
//   renderApplicaton();
// }
