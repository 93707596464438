import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, logout } from "../store/session";
import { useState } from "react";
import PasswordRequirements from "./PasswordRequirements";
import { fetchUsers, getUserByEmail, updateUser } from "../store/users";
import { validInputs, checkErrors, isEmail } from "./Utils";
import { useHistory } from "react-router-dom";
import csrfFetch from "../store/csrf";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
import DashboardHeader from "./DashboardHeader";
import { togglePasswordVisibility } from "./Utils";
import { sendVerificationEmail } from "../store/session";
import { getLoading, setLoading } from "../store/loading";
import { useEffect } from "react";
import { BUTTON_CLASS, BUTTON_CLASS_WHITE, INPUT_CLASS } from "../constants";


const Settings = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const [email, setEmail] = useState(currentUser.email);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState([]);
  const [emailErrors, setEmailErrors] = useState([]);
  const otherUser = useSelector(getUserByEmail(email));
  const [submitted, setSubmitted] = useState(false);
  const [showPasswordButtons, setShowPasswordButtons] = useState(false);
  const [showEmailUpdate, setShowEmailUpdate] = useState(false);
  const loading = useSelector(getLoading);
  const [currentPasswordType, setCurrentPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [newPasswordType, setNewPasswordType] = useState('password');
  

  useEffect(() => {
    dispatch(fetchUsers());
  }, [])

  const checkOriginalPassword = async (email, password) => {
    const res = await csrfFetch('/api/validate-user', {
      method: 'POST',
      body: JSON.stringify({
        email,
        password
      })
    });
    if (res.ok) {
      const user = await res.json();
      if (user.id) return user;
    }
    return null;
  }

  const handleUpdatePassword = async e => {
    e.preventDefault();
    setSubmitted(true);
    setLoading(1);
    setErrors([]);

    let currentErrors = validInputs(newPassword, confirmPassword, currentUser.name);
    setErrors(currentErrors);

    if (currentErrors.length) {
      dispatch(setLoading(0));
      return null;
    } else {
      const validatedUser = await checkOriginalPassword(currentUser.email, currentPassword);
      if (validatedUser) {
        let currentErrors = [];
        await dispatch(updateUser({id: currentUser.id, password: newPassword}))
        .catch(async (res) => {
          currentErrors = await checkErrors(res, setErrors);
        });
  
        if (!currentErrors.length) {
          setCurrentPassword('');
          setConfirmPassword('');
          setNewPassword('');
          setShowPasswordButtons(false);
          setSubmitted(false);
          await dispatch(logout())
          .catch(async (res) => {
            currentErrors = await checkErrors(res, setErrors);
          });
          if (!currentErrors.length) {
            history.push('/password-updated');
          } else {
            toast.error('There was an error logging you out. Please try again.');
          }
        }
      } else {
        setErrors([...errors, 'Original password incorrect.'])
      }
      dispatch(setLoading(0));
    }
  }

  const handleInputPassword = e => {
    setCurrentPassword(e.target.value);
    setShowPasswordButtons(e.target.value.length > 0 ? true : false);
    setSubmitted(false);
  }

  const handleUpdateEmail = async e => {
    e.preventDefault();
    setEmailErrors([]);
    dispatch(setLoading(2));
    if (!isEmail(email)) {
      setEmailErrors([...errors, 'Invalid email address.']);
      dispatch(setLoading(0));
      return;
    }
    let currentErrors = [];
    if (otherUser && otherUser.id) {
      setEmailErrors(['Email address already exists']);
      dispatch(setLoading(0));
      return;
    }
    let res = await dispatch(updateUser({id: currentUser.id, desired_email: email.toLowerCase()}))
    .catch(async (res) => {
      currentErrors = await checkErrors(res, setEmailErrors);
      toast.error('Could not update email in the database.')
      return;
    });
    if (!currentErrors.length && res.id) {  
      let resp = await sendVerificationEmail(currentUser.id, true, email.toLowerCase())
      .catch(() => {
        currentErrors = ['Email update failed.'];
        toast.error('We are unable to send a verification email at this time. Please try again later.');
        dispatch(setLoading(0));
        return;
      }); 
      if (resp.ok) {
        setShowEmailUpdate(false);
        history.push(`/email-confirmation/${email}`);
      } else {
        toast.error('We are unable to send a verification email at this time. Please try again later.');
        dispatch(setLoading(0));
        return;
      }
      
    } else {
      toast.error("There was a problem updating your email.");
    }
    dispatch(setLoading(0));
  }

  const handleGoHome = (e) => {
    e.preventDefault();
    history.push('/');
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setShowPasswordButtons(false);
    setCurrentPassword('');
    setConfirmPassword('');
    setNewPassword('');
    setSubmitted(false);
  }

  const currentPasswordInput = document.getElementById("current-password");
  const newPasswordInput = document.getElementById("new-password");
  const confirmPasswordInput = document.getElementById("confirm-password");


  return (

    <div className="flex flex-col w-full h-screen items-start">
      <DashboardHeader settings={true} />
      <div className="flex flex-row justify-start cursor-pointer text-brand-primary ml-8 font-semibold h-12 items-center" >
      <button onClick={handleGoHome} className="group h-full flex flex-row items-center justify-center p-4 ">
          <i className="fa-solid fa-arrow-left hover:text-bp2 active:text-bp3 mr-4 text-brand-primary group-hover:text-bp2 group-active:text-bp3"></i>
          <p className="text-brand-primary group-hover:text-bp2 group-active:text-bp3">
          Back to Board
          </p>
        </button>
      </div>
      <div className="flex flex-col justify-center items-center w-full">
        <div className="flex flex-col items-center h-screen">
          <p className="text-4xl font-semibold m-8">Settings</p>
          <div className="flex flex-col items-center bg-background-disabled min-w-[600px] p-6">
            {showEmailUpdate ? 
            <div className="flex flex-col justify-between w-3/4 mb-5">
              <div className="mb-5">
                <label className="font-semibold">Email address</label>
                <input className={INPUT_CLASS + 'w-full'} type="email" value={email.toLowerCase()} onChange={e => setEmail(e.target.value)} />
                {<ul className='min-h-[16px]'>
                  {emailErrors.map(error => {
                    if (error != '') {
                      return <li className="text-error-red" key={error}>{error}</li>
                    }
                  })}
              </ul>}
              </div>
              <div className="w-full flex flex-row justify-evenly">
                <button onClick={e => setShowEmailUpdate(false)} className={BUTTON_CLASS_WHITE}>Cancel</button>
                <button className={BUTTON_CLASS + 'min-w-[150px]'} disabled={(!isEmail(email) || currentUser.email === email.toLowerCase())} onClick={handleUpdateEmail}>Update email address
                  {loading == 2 && <div className="spin"></div>}
                </button>
              </div>
            </div>        
            :
            <div className="flex flex-row justify-between w-3/4 mb-5">
              <div>
                <p className="font-semibold">Email address</p>
                <p>{currentUser.email}</p>
              </div>
              <div>
                <i onClick={e => setShowEmailUpdate(true)} className="fa-solid fa-pencil cursor-pointer"></i>
              </div>
            </div>
            }
            <form className="w-3/4">
              <label className="font-semibold">Current password</label>
              <div className='flex flex-row items-center justify-end w-full'>
                <input id="current-password" className={INPUT_CLASS + 'w-full'} type={currentPasswordType} value={currentPassword} onChange={handleInputPassword} />
                {currentPasswordType === 'password' ?
                <i onClick={togglePasswordVisibility(currentPasswordType, setCurrentPasswordType, currentPasswordInput)} className="fa-solid fa-eye-slash cursor-pointer absolute mr-3"></i>
                :
                <i onClick={togglePasswordVisibility(currentPasswordType, setCurrentPasswordType, currentPasswordInput)} className="fa-solid fa-eye cursor-pointer absolute mr-3"></i>
              }
              </div>            
              <a href="/forgot-password" className='block text-link-blue font-semibold underline decoration-solid mb-5'>Forgot Your Password?</a>
              <label className="font-semibold">Enter new password</label>
              <div className='flex flex-row items-center justify-end w-full mb-5'>
                <input id="new-password" className={INPUT_CLASS + 'w-full'} type={newPasswordType} value={newPassword} onChange={e => {setNewPassword(e.target.value); setSubmitted(false);}} />
                {newPasswordType === 'password' ?
                <i onClick={togglePasswordVisibility(newPasswordType, setNewPasswordType, newPasswordInput)} className="fa-solid fa-eye-slash cursor-pointer absolute mr-3"></i>
                :
                <i onClick={togglePasswordVisibility(newPasswordType, setNewPasswordType, newPasswordInput)} className="fa-solid fa-eye cursor-pointer absolute mr-3"></i>
              }
              </div>            
              <PasswordRequirements password={newPassword} submitted={submitted}/>
              <label className="font-semibold">Re-enter new password</label>
              <div className='flex flex-row items-center justify-end w-full mb-5'>
                <input id="confirm-password" className={INPUT_CLASS + 'w-full'} type={confirmPasswordType} value={confirmPassword} onChange={e => {setConfirmPassword(e.target.value); setSubmitted(false);}} />
                {confirmPasswordType === 'password' ?
                <i onClick={togglePasswordVisibility(confirmPasswordType, setConfirmPasswordType, confirmPasswordInput)} className="fa-solid fa-eye-slash fa-pencil cursor-pointer absolute mr-3"></i>
                :
                <i onClick={togglePasswordVisibility(confirmPasswordType, setConfirmPasswordType, confirmPasswordInput)} className="fa-solid fa-eye fa-pencil cursor-pointer absolute mr-3"></i>              
              }
              </div>                 
            {<ul className='mb-3 min-h-[70px]'>
              {errors.map(error => {
                if (error != '') {
                  return <li className="text-error-red font-bold" key={error}>{error}</li>
                }
              })}
            </ul>}
              {showPasswordButtons && 
              <div className="w-full flex flex-row justify-evenly">
                <button onClick={handleCancel} className="button-white">Cancel</button>
                <button disabled={loading == 1} className="button w-1/2" onClick={handleUpdatePassword}>
                  {loading == 1 ? 
                  <Spinner/> :
                  'Update Password'
                }                
                </button>
              </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>

  )

}

export default Settings;